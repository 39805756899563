<div
  [style.background-image]="backgroundUrlString"
  class="hero min-h-screen"
  id="div-bg"
>
  <div class="hero-overlay bg-opacity-60"></div>
  <div class="hero-content text-neutral-content text-center">
    <div class="max-w-md card card-body bg-base-100 card-glass">
      <h1 class="mb-5 text-5xl font-bold">
        {{ "hello" | translate }}
      </h1>
      <p class="mb-5">
        {{ "login_message" | translate }}
      </p>
      <button
        (click)="googleLogin()"
        class="text-white w-full bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2 login-google-button"
        type="button"
      >
        <svg
          aria-hidden="true"
          class="mr-2 -ml-1 w-4 h-4"
          data-icon="google"
          data-prefix="fab"
          focusable="false"
          role="img"
          viewBox="0 0 488 512"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
            fill="currentColor"
          ></path>
        </svg>
        {{ "login_with_google" | translate }}
        <div></div>
      </button>

      <div *ngIf="showErrorModal" class="alert alert-error" role="alert">
        <svg
          class="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"/>
        </svg>
        <span>Error! {{ "something_wrong" | translate }}.</span>
      </div>
    </div>
  </div>
</div>
