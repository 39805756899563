<input type="checkbox" id="my-modal" class="modal-toggle" />
<div class="modal modal-bottom sm:modal-middle">
  <div class="modal-box">
    <h3 class="font-bold text-lg">{{ "actions.delete" | translate }}</h3>
    <p
      class="py-4"
      [innerHTML]="
        'actions.delete_race_confirmation' | translate : { race: race.name }
      "
    ></p>
    <div class="modal-action">
      <label
        for="my-modal"
        class="btn btn-outline"
        (click)="cancelDeleteRace()"
        >{{ "actions.cancel" | translate }}</label
      >
      <label
        for="my-modal"
        class="btn btn-error"
        (click)="deleteRace(raceSelected.$key)"
        >{{ "actions.delete" | translate }}</label
      >
    </div>
  </div>
</div>

<div
  class="card card-compact bg-base-100 shadow-xl"
  attr.id="full-card{{ race.$key }}"
>
  <div class="card-body px-2">
    <h2 class="card-title text-base-content">
      <fa-icon [icon]="race.surfaceIcon"></fa-icon>
      {{ race.name }}
    </h2>
    <h2 class="card-title text-base-content">
      {{ race.date | date : "mediumDate" }}
    </h2>
    <div
      class="stats bg-white text-secondary-content stats-vertical lg:stats-horizontal shadow"
    >
      <div class="stat px-3">
        <div class="stat-title">{{ "distance" | translate }}</div>
        <div class="stat-value text-xl text-info-content">
          {{ race.distanceValue }} {{ race.distanceUnit }}
        </div>
      </div>
      <div class="stat px-3" *ngIf="race.elevationGain > 0">
        <div class="stat-title">{{ "elevation" | translate }}</div>
        <div class="stat-value text-xl text-info-content">
          {{race.elevationGain}} D+
        </div>
      </div>

      <div class="stat px-3">
        <div class="stat-title">{{ "time" | translate }}</div>
        <div class="stat-value text-xl text-info-content">
          {{ race.time }}
        </div>
      </div>

      <div class="stat px-3">
        <div class="stat-title">{{ "pace" | translate }}</div>
        <div class="stat-value text-xl text-info-content">
          {{ race.pace }}/{{ race.distanceUnit }}
        </div>
      </div>
    </div>
    <div *ngIf="!generatingImage" class="card-actions justify-end">
      <button class="btn btn-success" (click)="generateImage(race.$key)" *ngIf="enableGenerateImage">
        <fa-icon [icon]="faImage"></fa-icon>
      </button>
      <button *ngIf="race.externalActivityUrl" class="btn btn-info">
        <a href="{{ race.externalActivityUrl }}" target="_blank">
          <fa-icon [icon]="faExternalLinkAlt"></fa-icon>
        </a>
      </button>
      <button class="btn btn-primary" [routerLink]="['/edit-race', race.$key]">
        <fa-icon [icon]="faPen"></fa-icon>
      </button>
      <label
        for="my-modal"
        class="btn btn-error"
        (click)="deleteRaceModal(race)"
        ><fa-icon [icon]="faTrash"></fa-icon
      ></label>
    </div>
  </div>
</div>
