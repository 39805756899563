<div class="background">
  <div class="hero text-primary min-h-screen">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left">
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl santi-glass">
          <div class="card-body">
            <h1 class="text-5xl font-bold">
              {{ "create_account" | translate }} 😍
            </h1>
            <p>
              {{ "login_message" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div class="card-body">
          <form [formGroup]="signUpForm">
            <div class="form-control">
              <label class="label">
                <span class="label-text">{{ "email" | translate }}</span>
              </label>
              <input
                type="text"
                placeholder="Email"
                [ngClass]="
                  (!signUpForm.controls['email'].valid &&
                    signUpForm.controls['email'].touched) ||
                  (invalidControls.includes('email') &&
                    !signUpForm.controls['email'].touched)
                    ? 'input input-bordered bg-white bg-white border-red-500 text-red-600'
                    : 'input input-bordered bg-white bg-white'
                "
                formControlName="email"
              />
            </div>
            <div class="form-control">
              <label class="label">
                <span class="label-text">{{ "password" | translate }}</span>
              </label>
              <div class="join">
                <input
                  [type]="showPassword ? 'text' : 'password'"
                  placeholder="Contraseña"
                  [ngClass]="
                    (!signUpForm.controls['password'].valid &&
                      signUpForm.controls['password'].touched) ||
                    (invalidControls.includes('password') &&
                      !signUpForm.controls['password'].touched)
                      ? 'input input-bordered bg-white bg-white border-red-500 join-item grow'
                      : 'input input-bordered bg-white bg-white join-item grow'
                  "
                  formControlName="password"
                />
                <button class="btn join-item" (click)="showPasswordToggle()">
                  <fa-icon *ngIf="showPassword" [icon]="faEye"></fa-icon>
                  <fa-icon *ngIf="!showPassword" [icon]="faEyeSlash"></fa-icon>
                </button>
              </div>
            </div>
          </form>
          <small
            >{{ "already_have_account" | translate }}
            <a class="label-text-alt link link-hover link-info" href="/login">
              {{ "click_here" | translate  }}
            </a>
          </small>
          <div class="flex flex-col w-full border-opacity-50">
            <div class="grid h-20 card place-items-center">
              <div class="form-control w-full">
                <button class="btn btn-primary" (click)="signUp()">
                  {{ "create_account" | translate }}
                </button>
              </div>
            </div>
            <div class="divider">O</div>
            <div class="grid h-20 card place-items-center">
              <div class="form-control w-full">
                <div class="sm:px-0 max-w-sm login-google-button">
                  <button
                    (click)="authService.googleAuth()"
                    type="button"
                    class="text-white w-full bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2 login-google-button"
                  >
                    <svg
                      class="mr-2 -ml-1 w-4 h-4"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="google"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 488 512"
                    >
                      <path
                        fill="currentColor"
                        d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                      ></path>
                    </svg>
                    {{ "sign_up_with_google" | translate }}
                    <div></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
