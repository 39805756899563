<div class="background">
  <div class="hero text-primary min-h-screen">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left">
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl santi-glass">
          <div class="card-body">
            <h1 class="text-5xl font-bold">Contraseña recuperada ✅</h1>
            <p>
              Te enviamos un mail a tu correo electronico para que puedas volver
              a ingresar 😊
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
