<div class="drawer">
  <input class="drawer-toggle" id="my-drawer-3" type="checkbox"/>
  <div class="drawer-content flex flex-col">
    <!-- Navbar -->
    <div class="w-full navbar bg-primary">
      <div class="flex-none lg:hidden">
        <label
          class="btn btn-square btn-ghost text-primary-content"
          for="my-drawer-3"
        >
          <fa-icon [icon]="faBars"></fa-icon>
        </label>
      </div>
      <div class="flex-1 px-2 mx-2">
        <a
          [routerLink]="'/races'"
          class="btn btn-ghost text-primary-content normal-case text-xl"
        >{{ "my_races" | translate }}</a
        >
      </div>
      <div class="flex-none hidden lg:block">
        <ul
          class="menu menu-horizontal dropdown-content p-2 shadow rounded-box text-primary-content z-1"
        >
          <div *ngIf="!noData" class="flex-none gap-2">
            <div class="form-control">
              <div class="join">
                <input
                  #filter
                  class="input join-item text-primary"
                  placeholder="{{ 'search_race' | translate }}"
                  (keyup)="inputChanged(filter.value, false)"
                />
                <button
                  (click)="searchRace(filter.value, false)"
                  class="btn join-item"
                >
                  <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                </button>
              </div>
            </div>
          </div>
          <li *ngIf="!noData" tabindex="0">
            <details class="w-100 details-goToYear" id="goToYearDetail">
              <summary class="font-bold text-base w-100">
                {{ "go_to_year" | translate }}
              </summary>
              <ul class="p-2 full-width year-selector">
                <div *ngFor="let year of yearsArray">
                  <li class="text-primary">
                    <a (click)="scrollToYear(year, false)">{{ year }}</a>
                  </li>
                </div>
              </ul>
            </details>
          </li>
          <li class="text-primary-content self-center">
            <a (click)="logout()"
            >
              <fa-icon [icon]="faRightFromBracket" class="fa-xl"></fa-icon>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="min-h-screen">
      <div *ngIf="loading" class="text-center pt-10">
        <h1 class="text-5xl font-bold pb-5">Cargando...</h1>
        <span class="loading loading-ball loading-lg text-3xl"></span>
        <span class="loading loading-ball loading-lg text-3xl"></span>
        <span class="loading loading-ball loading-lg text-3xl"></span>
      </div>
      <div
        *ngIf="noData && !loading"
        class="flex justify-center items-center text-center pt-12"
      >
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl santi-glass">
          <div class="card-body">
            <h1 class="text-5xl font-bold pb-5">
              {{ "no_races_loaded" | translate }}
            </h1>
            <button (click)="addRace()" class="btn btn-primary">
              {{ "actions.create_race" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!loading" class="flex flex-wrap items-center">
        <div *ngIf="!isFilteringRace" class="content-center w-full">
          <div
            class="flex flex-col w-full border-opacity-50">
            <div class="divider text-primary-content h-12">
              <div class="btn btn-secondary">{{ "summary" | translate }}</div>
            </div>
            <app-metrics-summary [races]="allRaces" class="m-auto mt-4"></app-metrics-summary>
          </div>
        </div>
        <div
          *ngFor="let year of allRacesByYear; let i = index"
          class="content-center w-full"
        >
          <div
            class="flex flex-col w-full border-opacity-50"
            id="{{ year.date }}"
          >
            <div class="divider text-primary-content h-12">
              <div class="btn btn-secondary">{{ year.date }}</div>
            </div>
            <app-metrics-summary [races]="year.races" class="m-auto mt-4"></app-metrics-summary>
            <div class="flex flex-wrap content-center justify-start w-full">
              <div
                *ngFor="let race of year.races"
                class="w-full xl:w-1/3 md:w-1/2 p-5"
              >
                <div
                  *ngIf="raceCreated"
                  class="toast toast-center toast-top animated fadeOut"
                >
                  <div class="alert alert-success">
                    <span>{{ "race_created_success" | translate }}</span>
                  </div>
                </div>
                <app-race-card
                  (deleteRaceEvent)="deleteRace($event)"
                  [race]="race"
                ></app-race-card>
              </div>
            </div>
          </div>
        </div>
        <label
          (click)="addRace()"
          *ngIf="!noData"
          class="btn fixed z-50 bottom-5 right-8 w-20 h-20 drop-shadow-lg flex justify-center items-center text-4xl btn-primary hover:drop-shadow-2xl"
        >&#43;</label
        >
      </div>
    </div>
  </div>
  <div class="drawer-side">
    <label class="drawer-overlay" for="my-drawer-3"></label>
    <ul class="menu p-4 w-80 h-full bg-base-200 z-1">
      <div *ngIf="!noData" class="flex-none gap-2">
        <div class="form-control">
          <div class="join">
            <input
              #filterMobile
              class="input input-bordered join-item text-primary"
              placeholder="{{ 'search_race' | translate }}"
              (keydown)="inputChanged(filterMobile.value, true)"
            />
            <button
              (click)="searchRace(filterMobile.value, true)"
              class="btn btn-primary join-item"
            >
              <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <label *ngIf="!noData" class="btn btn-ghost btn-disabled">
        {{ "go_to_year" | translate }}</label
      >
      <!-- Sidebar content here -->
      <div *ngFor="let year of allRacesByYear; let i = index">
        <li>
          <a (click)="scrollToYear(year.date, true)">{{ year.date }}</a>
        </li>
      </div>
      <div class="divider"></div>
      <li>
        <a (click)="logout()">
          <fa-icon [icon]="faRightFromBracket" class="fa-xl"></fa-icon>
          {{ "sign_out" | translate }}
        </a>
      </li>
    </ul>
  </div>
</div>
