<div class="background">
  <div class="hero text-primary min-h-screen">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left">
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl santi-glass">
          <div class="card-body">
            <h1 class="text-5xl font-bold">{{ "hello" | translate }}</h1>
            <p>
              {{ "login_message" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div class="card-body">
          <div *ngIf="loginError" class="alert alert-error">
            <fa-icon [icon]="faCircleExclamation" class="fa-xl"></fa-icon>
            <div>
              <h3 class="font-bold">{{ "error" | translate }} 😭</h3>
              <div class="text-xs">{{ loginErrorMessage | translate }}</div>
            </div>
            <div>
              <button class="btn btn-sm btn-ghost">X</button>
            </div>
          </div>
          <form (keydown.enter)="login()" [formGroup]="loginForm">
            <div class="form-control">
              <label class="label">
                <span class="label-text">{{ "email" | translate }}</span>
              </label>
              <input
                [ngClass]="
                  (!loginForm.controls['email'].valid &&
                    loginForm.controls['email'].touched) ||
                  (invalidControls.includes('email') &&
                    !loginForm.controls['email'].touched)
                    ? 'input input-bordered bg-white border-red-500 text-red-600'
                    : 'input input-bordered bg-white'
                "
                email
                formControlName="email"
                placeholder="Email"
                type="email"
              />
            </div>
            <div class="form-control">
              <label class="label">
                <span class="label-text">{{ "password" | translate }}</span>
              </label>
              <div class="join">
                <input
                  [ngClass]="
                    (!loginForm.controls['password'].valid &&
                      loginForm.controls['password'].touched) ||
                    (invalidControls.includes('password') &&
                      !loginForm.controls['password'].touched)
                      ? 'input input-bordered bg-white border-red-500 join-item grow'
                      : 'input input-bordered bg-white join-item grow'
                  "
                  [type]="showPassword ? 'text' : 'password'"
                  formControlName="password"
                  placeholder="Contraseña"
                />
                <button (click)="showPasswordToggle()" [ngClass]="
                    (!loginForm.controls['password'].valid &&
                      loginForm.controls['password'].touched) ||
                    (invalidControls.includes('password') &&
                      !loginForm.controls['password'].touched)
                      ? 'border-red-500'
                      : ''
                  "
                        aria-label="Show Password toggle button"
                        class="btn join-item" id="show-password-button">
                  <fa-icon *ngIf="showPassword" [icon]="faEye"></fa-icon>
                  <fa-icon *ngIf="!showPassword" [icon]="faEyeSlash"></fa-icon>
                </button>
              </div>
              <label class="label">
                <small>
                  <a class="link-info" href="/forgot-password">{{
                      "forgot_password_title" | translate
                    }}</a>
                </small>
              </label>
            </div>
          </form>
          <div class="flex flex-col w-full border-opacity-50">
            <div class="grid h-20 card place-items-center">
              <div class="form-control w-full">
                <button (click)="login()" class="btn btn-primary">
                  {{ "login" | translate }}
                </button>
              </div>
            </div>
            <div class="divider">O</div>
          </div>
          <div class="form-control w-full">
            <div class="sm:px-0 max-w-sm login-google-button">
              <button
                (click)="authService.googleAuth()"
                class="text-white w-full bg-[#4285F4] hover:bg-[#4285F4]/90 focus:ring-4 focus:outline-none focus:ring-[#4285F4]/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center justify-between dark:focus:ring-[#4285F4]/55 mr-2 mb-2 login-google-button"
                type="button"
              >
                <svg
                  aria-hidden="true"
                  class="mr-2 -ml-1 w-4 h-4"
                  data-icon="google"
                  data-prefix="fab"
                  focusable="false"
                  role="img"
                  viewBox="0 0 488 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    fill="currentColor"
                  ></path>
                </svg>
                {{ "login_with_google" | translate }}
                <div></div>
              </button>
            </div>
          </div>
          <label class="label">
            <span class="label-text">{{ "create_account_label" | translate }}
              <a class="link-info" href="/sign-up">
                {{ "create_now" | translate }}</a>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
