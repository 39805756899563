import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './forgot-password-success.component.html',
  styleUrls: ['./forgot-password-success.component.css'],
})
export class ForgotPasswordSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
