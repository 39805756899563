<div class="stats stats-vertical lg:stats-horizontal shadow">
  <div class="stat">
    <div class="stat-title text-center lg:text-start">Carreras</div>
    <div class="stat-value text-secondary text-center lg:text-start">
      {{ races.length }}
    </div>
  </div>

  <div class="stat">
    <div class="stat-title text-center lg:text-start">Distancia</div>
    <div class="stat-value text-primary text-center lg:text-start">
      {{ kilometersCounter | number: '1.0-0' }} Km
    </div>
  </div>

  <div class="stat" *ngIf="elevationCounter > 0">
    <div class="stat-title text-center lg:text-start">Desnivel</div>
    <div class="stat-value text-primary text-center lg:text-start">
      {{ elevationCounter | number: '1.0-0' }} D+
    </div>
  </div>

  <div class="stat">
    <div class="stat-title text-center lg:text-start">Tiempo</div>
    <div class="stat-value text-secondary text-center lg:text-start">
      {{ totalTime }}
    </div>
  </div>
</div>
