<div class="background">
  <div class="hero text-primary min-h-screen">
    <div class="hero-content flex-col lg:flex-row-reverse">
      <div class="text-center lg:text-left">
        <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl santi-glass">
          <div class="card-body">
            <h1 class="text-5xl font-bold">{{ "forgot_password_title" | translate }} 😅</h1>
            <p>
              {{ "forgot_password_description" | translate }}
            </p>
          </div>
        </div>
      </div>
      <div class="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div class="card-body">
          <div class="form-control">
            <label class="label">
              <span class="label-text">{{ "email" | translate }}</span>
            </label>
            <input
              #passwordResetEmail
              class="input input-bordered bg-white"
              placeholder="Email"
              type="text"
            />
          </div>
          <div class="flex flex-col w-full border-opacity-50">
            <div class="grid h-20 card place-items-center">
              <div class="form-control">
                <button
                  (click)="authService.forgotPassword(passwordResetEmail.value)"
                  class="btn btn-primary"
                >
                  {{ "recover_password" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
